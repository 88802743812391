body {
  font-family: Roboto, sans-serif;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-layout-sider-children {
  background-color: rgb(251, 131, 213); 
}
.ant-menu-item-selected{
  background-color: rgba(218, 54, 166, 0.43) !important;
  color: white !important;
}

.logo {
  height: 100px;
  margin: 16px;
  background-color: white;
  border-radius: 12px;
}

.ant-layout {
  min-height: 100vh;
}
.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}
.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.sm-logo {
  display: none;
  margin-top: 30px;
}

.logo-image{
  width: 90%;
  object-fit: cover;
  position: relative;
  top: -32px;

}
.ant-layout-sider-collapsed .sm-logo {
  display: block;
}
.ant-layout-sider-collapsed .lg-logo {
  display: none;
}

main {
  height: 85vh;
  overflow-y: scroll;
}
main::-webkit-scrollbar {
  width: 0px;
}

.logo + .ant-menu, .ant-menu-sub {
  background-color: rgb(251, 131, 213);
  

}

.ant-menu-title-content {
  font-size: 16px;
}
.ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
}
.ant-layout-header p {
  line-height: 20px;
  font-size: 13px;
}
.ant-layout-header div .badge {
  top: 16px;
  right: -6px;
}
.error {
  color: red;
  font-size: 12px;
}

.drag-and-drop{
  background-color: rgb(238, 238, 238);
  
}

.user-logo{
  width: 40px !important;
  
  background-color: white !important;
  padding: 8px;
  border-radius: 50%;
}

.ant-menu-sub{
  background-color: rgb(245, 114, 203) !important;
}

.info-user{
  color: rgb(238, 75, 186);
}

/* ViewOrder.css */
.order-info {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.total-price {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}

.comment-section {
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 15px;
  border-left: 5px solid #ccc;
  border-radius: 5px;
}

.comment-title {
  font-weight: bold;
  margin-bottom: 10px;
}




@media (min-width: 1281px){

}

@media (min-width: 1024px) and (max-width: 1280px){
  .login-card {
    width: 400px !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px){
  .login-card {
    width: 400px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px){
  .login-card {
    width: 350px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px){
  .login-card {
    width: 80% !important;
  }

}

@media (min-width: 320px) and (max-width: 480px){
  .login-card {
    width: 95% !important;
  }
  .ant-layout-sider-collapsed{
    display: none;
  }
}